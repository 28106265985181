export default {
  // Default color applied by Chakra when using main color key: 500
  main: '#FCB301',
  error: '#FF3F3B',
  warning: '#FFD43D',
  success: '#2B917B',
  white: '#FFFFFF',
  beige: {
    50: '#F4F0ED',
  },
  orange: {
    100: '#FFE8AD',
    300: '#FFCA4B',
    400: '#fac254',
    500: '#FCB301',
  },
  green: {
    100: '#d1fcc2',
    500: '#35AA01',
  },
  red: {
    100: '#FED7D7',
    300: '#FF825B',
    500: '#FF3F3B',
  },
  grey: {
    10: '#f4f4f4',
    20: '#EEEEEE',
    50: '#D5D4D3',
    70: '#BBBBBB',
    100: '#B1B1B1',
    200: '#7a7a7b',
    500: '#474747',
    600: '#383838',
    900: '#333333',
  },
  blueGrey: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
  },
  black: '#000000',
  blue: {
    500: '#4C4981',
  },
};
