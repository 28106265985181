import React from 'react';
import { Box, Center, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { RestaurantSelect } from '../../../../common/components/RestaurantSelect/RestaurantSelect';
import { SelectOption } from '../../../../common/components/Select/model/selectOption';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { RestaurantInfo } from '../../../../contexts/SessionContext/SessionContext';
import { useRestaurantsData } from '../../../../services/restaurants';
import colors from '../../../../styles/colors';
import RelevoScanner from '../../common/components/RelevoScanner';
import { getAssignSelectStyle } from '../../styles/relevo-assign-select-style';
import { SelectAssignmentMode } from '../SelectAssignmentMode/SelectAssignmentMode';
import ReturnSumUp from '../SumUp/ReturnSumUp';

const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);

type ScanReturnProps = {
  restaurants: RestaurantInfo[];
};

export const ScanReturn = ({ restaurants }: ScanReturnProps) => {
  const translations = useTranslations();
  const { restaurantId, setRestaurantId } = useScannedRelevosContext();
  const { isQuickContactsEnabled } = useRestaurantsData();

  return (
    <Box>
      <VStack spacing="20px">
        <Flex bgColor={colors.beige[50]} width="100%" wrap="wrap" px={3} py={6}>
          {isQuickContactsEnabled ? (
            <Box display={['block', 'block', 'block', 'none', 'none']} width={['100%', '100%', '50%', '50%']}>
              <SelectAssignmentMode />
            </Box>
          ) : null}
          <Box height="100%" py={4} width={['100%', '100%', '100%', '100%', '45%']}>
            <HStack>
              <img src={getIconPath('bowl.svg')} alt="bowl icon" />
              <img src={getIconPath('cup.svg')} alt="cup icon" style={{ marginBottom: '10px' }} />
              <VStack alignItems={'left'} pl={2}>
                <Text textAlign="left" fontWeight="bold" fontSize="16px">
                  {translations('return_scan_relevos_for_return')}
                </Text>
                <Text textAlign="left" fontSize="16px">
                  {translations('return_relevos_info_extended')}
                </Text>
              </VStack>
            </HStack>
          </Box>
          <Flex wrap="wrap">
            <Center mx={'auto'}>
              <RelevoScanner />
            </Center>
            <Box
              width={'270px'}
              justifyContent={'center'}
              alignItems={'center'}
              mx={'auto'}
              px={2}
              py={'auto'}
              display={'flex'}
            >
              <Box w={'100%'}>
                <RestaurantSelect
                  userRestaurants={restaurants}
                  restaurantId={restaurantId}
                  setRestaurantId={setRestaurantId}
                  styles={getAssignSelectStyle<SelectOption>()}
                />
              </Box>
            </Box>
          </Flex>
        </Flex>
      </VStack>
      <ReturnSumUp />
    </Box>
  );
};
